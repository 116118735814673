import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Local.vue'),
    },
    {
      path: '/zepena',
      name: 'zepena',
      component: () => import('@/views/zepena/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/carlinhos',
      name: 'carlinhos',
      component: () => import('../views/carlinhos/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/vitorino',
      name: 'vitorino',
      component: () => import('../views/vitorino/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/sales',
      name: 'sales',
      component: () => import('../views/sales/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/parquecm',
      name: 'parquecm',
      component: () => import('../views/senha/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/santamaria',
      name: 'santamaria',
      component: () => import('../views/santamaria/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/baronesa',
      name: 'baronesa',
      component: () => import('../views/baronesa/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/senha',
      name: 'senha',
      component: () => import('@/views/senha/senha-lista/SenhaLista.vue'),
    },
    {
      path: '/alert',
      name: 'alert',
      component: () => import('../views/sweet-alert/SweetAlert.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
