<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-relatorio
      class="mb-1"
      variant="warning"
    >
      <feather-icon
        icon="BarChart2Icon"
        class="mr-50"
      />
      <span class="align-middle">Gerar Relatório</span>
    </b-button>
    <b-row>
      <!-- Modal Detalhes -->
      <b-modal
        id="modal-1"
        title="Detalhes Kit de Senha"
        ok-only
      >
        <b-card-text v-if="kit">
          <h5>Kit: <b>{{ kit }} </b></h5>
          <h5>Categoria: <b>{{ dadosSenha.categoria }} </b></h5>
          <h5>Valor: <b>{{ dadosSenha.valor }} </b></h5>
          <h5>Puxador: <b style="text-transform: uppercase">{{ dadosSenha.nome }} </b></h5>
          <h5>CPF: <b>{{ dadosSenha.cpf }} </b></h5>
          <h5>Telefone: <b>{{ dadosSenha.telefone }} </b></h5>
          <h5>Estereiro: <b style="text-transform: uppercase">{{ dadosSenha.estereiro }} </b></h5>
          <h5>Cavalor Puxador: <b style="text-transform: uppercase">{{ dadosSenha.cavaloPuxar }} </b></h5>
          <h5>Cavalor Esteireiro: <b style="text-transform: uppercase">{{ dadosSenha.cavaloEsteira }} </b></h5>
          <h5>Representação: <b style="text-transform: uppercase">{{ dadosSenha.cavaloEsteira }} </b></h5>
          <h5>Cidade: <b style="text-transform: uppercase">{{ dadosSenha.cidade }} </b></h5>
          <h5>Premiação Extra: <b>{{ dadosSenha.obs }} </b></h5>
          <h5>Status: <b style="text-transform: uppercase">{{ dadosSenha.status }} </b></h5>
          <h5>Data Inscrição: <b>{{ dadosSenha.dataCadastro }} </b></h5>

        </b-card-text>
      </b-modal>
      <!-- Modal Cadastro Vaqueiro -->
      <b-modal
        id="modal-cadpuxador"
        title="Cadastrar puxador"
        ok-only
        ok-title="Cadastrar"
        size="lg"
        @ok="cadastrarPuxador"
      >
        <b-form>
          <b-row>
            <b-col md="6">
              <h6>Categoria</h6>
              <b-form-group>
                <v-select
                  v-model="categoria"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionCategoria"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <h6>Kit de Senha</h6>
              <b-form-group>
                <v-select
                  v-model="kitSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionKit"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Puxador"
                label-for="mc-last-name"
              >
                <b-form-input
                  id="mc-last-name"
                  v-model="puxador"
                  style="text-transform: uppercase"
                  placeholder="Puxador"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cavalo Puxador"
                label-for="mc-city"
              >
                <b-form-input
                  id="mc-city"
                  v-model="cavalo_puxador"
                  style="text-transform: uppercase"
                  placeholder="Cavalo"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Estereiro"
                label-for="mc-country"
              >
                <b-form-input
                  id="mc-country"
                  v-model="estereiro"
                  style="text-transform: uppercase"
                  placeholder="Estereiro"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cavalo Estereiro"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="cavalo_estereiro"
                    style="text-transform: uppercase"
                    placeholder="Cavalo"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cidade"
                label-for="mc-country"
              >
                <b-form-input

                  id="mc-country"
                  v-model="cidade"
                  style="text-transform: uppercase"
                  placeholder="Cidade"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Representação"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="representacao"
                    style="text-transform: uppercase"
                    placeholder="Representaçao"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <h6>Forma de Pagamento</h6>
              <b-form-group>
                <v-select
                  v-model="forma_pagamento"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionFp"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <h6>Status</h6>
              <b-form-group>
                <v-select
                  v-model="statusSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionStatus"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Desconto"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="desconto"
                    style="text-transform: uppercase"
                    placeholder="0"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Acréscimo"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="acrescimo"
                    style="text-transform: uppercase"
                    placeholder="0"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Observação"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="obs"
                    style="text-transform: uppercase"
                    placeholder="obs"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- Modal Atualizar Vaqueiro -->
      <b-modal
        id="modal-uppuxador"
        title="Atualizar Kit"
        ok-only
        ok-title="Atualizar"
        size="lg"
        @ok="atualizarKit"
      >
        <b-form v-if="upCheck">
          <b-row>
            <b-col md="6">
              <h6>Categoria</h6>
              <b-form-group>
                <v-select
                  v-model="row.categoria"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionCategoria2"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <h6>Kit de Senha</h6>
              <b-form-group>
                <v-select
                  v-model="row.kit"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionKit2"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Puxador"
                label-for="mc-last-name"
              >
                <b-form-input
                  id="mc-last-name"
                  v-model="row.puxador"
                  style="text-transform: uppercase"
                  placeholder="Puxador"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cavalo Puxador"
                label-for="mc-city"
              >
                <b-form-input
                  id="mc-city"
                  v-model="row.cavalo_puxador"
                  style="text-transform: uppercase"
                  placeholder="Cavalo"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Estereiro"
                label-for="mc-country"
              >
                <b-form-input
                  id="mc-country"
                  v-model="row.estereiro"
                  style="text-transform: uppercase"
                  placeholder="Estereiro"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cavalo Estereiro"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="row.cavalo_estereiro"
                    style="text-transform: uppercase"
                    placeholder="Cavalo"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cidade"
                label-for="mc-country"
              >
                <b-form-input

                  id="mc-country"
                  v-model="row.cidade"
                  style="text-transform: uppercase"
                  placeholder="Cidade"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Representação"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="row.representacao"
                    style="text-transform: uppercase"
                    placeholder="Representaçao"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <h6>Forma de Pagamento</h6>
              <b-form-group>
                <v-select
                  v-model="row.forma_pagamento"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionFp2"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <h6>Status</h6>
              <b-form-group>
                <v-select
                  v-model="row.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionStatus2"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Desconto"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="row.desconto"
                    style="text-transform: uppercase"
                    placeholder="0"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Acréscimo"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="row.acrescimo"
                    style="text-transform: uppercase"
                    placeholder="0"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Observação"
                label-for="mc-company"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-company"
                    v-model="row.extra"
                    style="text-transform: uppercase"
                    placeholder="obs"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
        id="modal-relatorio"
        title="Filtro"
        ok-only
        size="lg"
      >
        <b-form>
          <b-row>
            <b-col md="6">
              <h6>Categoria</h6>
              <b-form-group>
                <v-select
                  v-model="categoria"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionCategoria"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <h6>Forma de Pagamento</h6>
              <b-form-group>
                <v-select
                  v-model="forma_pagamento"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionFp"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <h6>Status</h6>
              <b-form-group>
                <v-select
                  v-model="statusSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionStatus"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="ml-1"
                variant="outline-primary"
              >
                <downloadexcel
                  :fetch="fetchDataFilter"
                  :fields="json_fields"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  Download Excel
                </downloadexcel>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-col
        cols="12"
      >
        <senha-totais
          :data="totais"
          :total-kit="totalKit"
        />
      </b-col>
      <b-col
        cols="12"
      >
        <b-card>
          <div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-cadpuxador
              variant="primary"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Puxador</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="ml-1"
              variant="outline-primary"
            >
              <downloadexcel
                :fetch="fetchData"
                :fields="json_fields"
                :before-generate="startDownload"
                :before-finish="finishDownload"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                Download Excel
              </downloadexcel>
            </b-button>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <!-- Atualizar parcela -->
                  <b-avatar
                    v-b-modal.modal-uppuxador
                    class="mr-1 cursor-pointer"
                    size="32"
                    :variant="`light-primary`"
                  >
                    <feather-icon
                      icon="EditIcon"
                      @click="carregarSenha(props.row)"
                    />
                    <b-tooltip
                      placement="top"
                    >
                      <p class="mb-0">
                        Atualizar
                      </p>
                    </b-tooltip>
                  </b-avatar>
                  <b-avatar
                    class="mr-1 cursor-pointer"
                    size="32"
                    :variant="`light-primary`"
                  >
                    <feather-icon
                      icon="PrinterIcon"
                      @click="imprimirSenha(props.row.id)"
                    />
                    <b-tooltip
                      placement="top"
                    >
                      <p class="mb-0">
                        Imprimir
                      </p>
                    </b-tooltip>
                  </b-avatar>
                  <b-avatar
                    class="mr-1 cursor-pointer"
                    size="32"
                    :variant="`light-danger`"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      @click="cancelarKit(props.row.id, props.row.kit)"
                    />
                    <b-tooltip
                      placement="top"
                    >
                      <p class="mb-0">
                        Cancelar Kit
                      </p>
                    </b-tooltip>
                  </b-avatar>
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="props.row.status === 'PAGO'"
                        @click="retirarPagamento(props.row.id, props.row.kit)"
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          class="mr-50"
                        />
                        <span>Retirar Pagamento</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-else
                        @click="confirmarPagamento(props.row.id, props.row.kit)"
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          class="mr-50"
                        />
                        <span>Confirmar Pagamento</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>

    </b-row>
  </div>

</template>

<script>
import {
  BBadge, BPagination, BForm, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BModal, BButton, BRow, BCol, BAvatar, BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import downloadexcel from 'vue-json-excel'

import senhaTotais from './SenhaTotais.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BModal,
    BButton,
    BAvatar,
    BTooltip,
    vSelect,
    senhaTotais,
    downloadexcel,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      upCheck: true,
      idVaquejada: 39,
      desconto: 0,
      acrescimo: 0,
      obs: null,
      categoria: { title: 'ABERTA' },
      kitSelected: { title: 'Senha' },
      forma_pagamento: '',
      optionKit: [],
      optionKit2: [],
      optionCategoria: [{ title: 'ABERTA' }, { title: 'ASPIRANTE' }, { title: 'FEMININO' }],
      optionCategoria2: ['ABERTA', 'ASPIRANTE', 'FEMININO'],
      statusSelect: '',
      optionStatus: [{ title: 'PAGO' }, { title: 'PENDENTE' }, { title: 'DOADO' }, { title: 'RESERVADO' }],
      optionStatus2: ['PAGO', 'PENDENTE', 'DOADO', 'RESERVADO'],
      optionFp: [{ title: 'DINHEIRO' }, { title: 'PIX' }, { title: 'CARTAO' }, { title: 'BOLETO' }],
      optionFp2: ['DINHEIRO', 'PIX', 'CARTAO', 'BOLETO'],
      host: 'http://localhost',
      dadosSenha: null,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Senha 1',
          field: 'senha1',
        },
        {
          label: 'Senha 2',
          field: 'senha2',
        },
        {
          label: 'Puxador',
          field: 'puxador',
        },
        {
          label: 'Data Insc.',
          field: 'data_incricao',
        },
        {
          label: 'Categoria',
          field: 'categoria',
        },
        {
          label: 'Valor',
          field: 'valor',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Ações',
          field: 'action',
        },
      ],
      totalKit: '',
      totais: [],
      kit: null,
      rows: [],
      searchTerm: '',
      puxador: null,
      cavalo_puxador: null,
      estereiro: null,
      cavalo_estereiro: null,
      cidade: null,
      representacao: null,
      status: [{
        1: 'Current',
        2: 'PAGO',
        3: 'Rejected',
        4: 'PENDENTE',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      kits: [],
      mapa: null,
      json_fields: {
        'Senha 1': 'senha1',
        'Senha 2': 'senha2',
        Nome: 'puxador',
        Cidade: 'cidade',
        Representacao: 'representacao',
        Valor: 'valor',
        Acrescimo: 'acrescimo',
        Desconto: 'desconto',
        'Forma Pagamento': 'forma_pagamento',
        Status: 'status',
        Obs: 'extra',
      },
      row: {},
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        PAGO : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    categoria() {
      this.optionKit = []
      if (this.categoria.title === 'ABERTA') {
        this.senhasDisponiveis('ABERTA')
      } else if (this.categoria.title === 'ASPIRANTE') {
        this.senhasDisponiveis('ASPIRANTE')
      } else if (this.categoria.title === 'FEMININO') {
        this.senhasDisponiveis('FEMININO')
      }
    },
    row() {
      this.optionKit2 = []
      if (this.row.categoria === 'ABERTA') {
        this.senhasDisponiveis('ABERTA')
      } else if (this.row.categoria === 'ASPIRANTE') {
        this.senhasDisponiveis('ASPIRANTE')
      } else if (this.row.categoria === 'FEMININO') {
        this.senhasDisponiveis('FEMININO')
      }
    },
  },
  created() {
    this.senhasDisponiveis('ABERTA')
    this.carregarSenhas()
  },
  methods: {
    atualizarKit() {
      const url = `${this.host}/api/atualizar/?vaquejada=${this.idVaquejada}&id=${this.row.id}&categoria=${this.row.categoria}&senha=${this.row.kit}&puxador=${this.row.puxador}&cavalo_puxador=${this.row.cavalo_puxador}&estereiro=${this.row.estereiro}&cavalo_estereiro=${this.row.cavalo_estereiro}&cidade=${this.row.cidade}&representacao=${this.row.representacao}&status=${this.row.status}&forma_pagamento=${this.row.forma_pagamento}&obs=${this.row.extra}&desconto=${this.row.desconto}&acrescimo=${this.row.acrescimo}`
      axios.get(url)
        .then(res => {
          if (res.data.resposta === 1) {
            this.rows[this.row.originalIndex].valor = res.data.valor
            this.rows[this.row.originalIndex].desconto = this.row.desconto
            this.rows[this.row.originalIndex].acrescimo = this.row.acrescimo
            Swal.fire({
              icon: 'success',
              title: 'Kit atualizado com sucesso',
              text: '',
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            Swal.fire({
              title: 'Algo de errado aconteceu.',
              text: '',
              timer: 1500,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    senhasDisponiveis(categoria) {
      axios.get(`${this.host}/api/senhasDisponiveis/${this.idVaquejada}/${categoria}`)
        .then(res => {
          res.data.map(se => this.optionKit.push({ title: se.senha }))
          res.data.map(se => this.optionKit2.push(se.senha))
        })
    },
    imprimirSenha(id) {
      window.open(`${this.host}/imprimir_senha1/${id}`)
    },
    showToast() {
      Swal.fire({
        title: 'Ops! Ocorreu um erro.',
        text: 'Você não permissão para executar esse função',
        timer: 3000,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    carregarSenha(props) {
      axios.get(`${this.host}/api/senha/${props.id}`)
        .then(() => {
          this.row = props
        })
    },
    carregarSenhas() {
      axios.get(`${this.host}/api/senhas/39`)
        .then(res => {
          this.rows = res.data.senhas
          const Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: res.data.totais[7].totais,
              subtitle: res.data.totais[7].categoria,
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: res.data.totais[2].totais,
              subtitle: res.data.totais[2].categoria,
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: res.data.totais[3].totais,
              subtitle: res.data.totais[3].categoria,
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: res.data.totais[4].totais,
              subtitle: res.data.totais[4].categoria,
              customClass: '',
            },
            {
              icon: 'DollarSignIcon',
              color: 'light-success',
              title: res.data.totais[5].totais,
              subtitle: res.data.totais[5].categoria,
              customClass: '',
            },
            {
              icon: 'DollarSignIcon',
              color: 'light-danger',
              title: res.data.totais[6].totais,
              subtitle: res.data.totais[6].categoria,
              customClass: '',
            },
          ]
          this.totais = Totais
          this.totalKit = res.data.totais[0].totais
        })
    },
    confirmarPagamento(id, kit) {
      const linhas = this.rows
      Swal.fire({
        title: 'Atenção!',
        text: `Confirmar o pagamento da kit ${kit}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Confirmar Pagamento',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`${this.host}/api/confirmarPagamento/${id}`)
            .then(res => {
              if (res.data === 1) {
                const ids = linhas.map(el => el.id)
                this.rows[ids.indexOf(id)].status = 'PAGO'
              }
            })
          Swal.fire({
            icon: 'success',
            title: 'Pagamento Confirmado',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          Swal.fire({
            title: 'Operacão cancelada',
            text: '',
            timer: 1500,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    retirarPagamento(id, kit) {
      const linhas = this.rows
      Swal.fire({
        title: 'Atenção!',
        text: `Retirar o pagamento da kit ${kit}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Retirar Pagamento',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`${this.host}/api/retirarPagamento/${id}`)
            .then(res => {
              if (res.data === 1) {
                const ids = linhas.map(el => el.id)
                this.rows[ids.indexOf(id)].status = 'PENDENTE'
              }
            })
          Swal.fire({
            icon: 'success',
            title: 'Pagamento Retirado',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          Swal.fire({
            title: 'Operacão cancelada',
            text: '',
            timer: 1500,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    cancelarKit(id, kit) {
      const linhas = this.rows
      Swal.fire({
        title: `Cancelar o kit ${kit}?`,
        text: 'Essa operacão não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Cancelar Kit',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`${this.host}/api/cancelarKit/${id}`)
            .then(res => {
              if (res.data === 1) {
                const ids = linhas.map(el => el.id)
                this.rows.splice(ids.indexOf(id), 1)
              }
            })
          Swal.fire({
            icon: 'success',
            title: 'Kit Cancelado',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          Swal.fire({
            title: 'Operacão cancelada',
            text: '',
            timer: 1500,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    cadastrarPuxador() {
      const body = {
        vaquejada: 39,
        categoria: this.categoria.title,
        senha: this.kitSelected.title,
        puxador: this.puxador,
        cavalo_puxador: this.cavalo_puxador,
        estereiro: this.estereiro,
        cavalo_estereiro: this.cavalo_estereiro,
        cidade: this.cidade,
        representacao: this.representacao,
        forma_pagamento: this.forma_pagamento.title,
        status: this.statusSelect.title,
        desconto: this.desconto,
        obs: this.obs,
        acrescimo: this.acrescimo,
      }
      const url = `${this.host}/api/cadastrar/?vaquejada=${body.vaquejada}&categoria=${body.categoria}&senha=${body.senha}&puxador=${body.puxador}&cavalo_puxador=${body.cavalo_puxador}&estereiro=${body.estereiro}&cavalo_estereiro=${body.cavalo_estereiro}&cidade=${body.cidade}&representacao=${body.representacao}&forma_pagamento=${body.forma_pagamento}&status=${body.status}&desconto=${body.desconto}&obs=${body.obs}&acrescimo=${body.acrescimo}`

      const kit = this.kitSelected.title.split('-')
      const senha = kit[0]
      axios.get(`${this.host}/api/verificarSenha/${this.idVaquejada}/${senha}`)
        .then(res2 => {
          if (res2.data === 0) {
            axios.get(url)
              .then(res => {
                if (res.data.resposta === 1) {
                  this.carregarSenhas()
                  this.kitSelected = null
                  this.puxador = null
                  this.cavalo_puxador = null
                  this.estereiro = null
                  this.cavalo_estereiro = null
                  this.cidade = null
                  this.representacao = null
                  Swal.fire({
                    icon: 'success',
                    title: 'Cadastro realizado com sucesso',
                    text: '',
                    timer: 1500,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                } else {
                  Swal.fire({
                    title: 'Algo de errado aconteceu.',
                    text: '',
                    timer: 1500,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              })
          } else {
            Swal.fire({
              title: 'Kit Reservado',
              text: 'Selecione outro kit',
              timer: 2000,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    async fetchData() {
      const dd = []
      const response = await axios.get(`${this.host}/api/senhas/39`)
      response.data.senhas.map(el => dd.push({
        senha1: el.senha1,
        senha2: el.senha2,
        puxador: el.puxador,
        cidade: el.puxador,
        representacao: el.representacao,
        categoria: el.categoria,
        valor: el.valor,
        acrescimo: el.acrescimo,
        desconto: el.desconto,
        forma_pagamento: el.forma_pagamento,
        status: el.status,
        extra: el.extra,
      }))
      return dd
    },
    async fetchDataFilter() {
      if (this.categoria.title) {
        this.categoria = this.categoria.title
      }
      if (this.statusSelect.title) {
        this.statusSelect = this.statusSelect.title
      }
      if (this.forma_pagamento.title) {
        this.forma_pagamento = this.forma_pagamento.title
      }
      const url = `${this.host}/api/filtro/?vaquejada=39&categoria=${this.categoria}&status=${this.statusSelect}&forma_pagamento=${this.forma_pagamento}`
      const dd = []
      const response = await axios.get(url)
      response.data.map(el => dd.push({
        categoria: el.categoria,
        puxador: el.puxador,
        kit: el.kit,
        valor: el.valor,
        forma_pagamento: el.forma_pagamento,
        status: el.status,
      }))
      this.forma_pagamento = ''
      this.statusSelect = ''
      this.categoria = ''
      return dd
    },
    startDownload() {
      Swal.fire({
        icon: 'success',
        title: 'Gerando Relatório',
        text: '',
        timer: 1000,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    finishDownload() {
      Swal.fire({
        icon: 'success',
        title: 'Relatório Gerado com Sucesso',
        text: '',
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
