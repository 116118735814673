import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import firebase from 'firebase/app'
import 'firebase/auth'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Config firebase

const firebaseConfig = {
  apiKey: 'AIzaSyDEU6RD2pcU1wor_3H84O-rTwQRxmYDOwk',
  authDomain: 'auth-1af50.firebaseapp.com',
  databaseURL: 'https://auth-1af50.firebaseio.com',
  projectId: 'auth-1af50',
  storageBucket: 'auth-1af50.appspot.com',
  messagingSenderId: '78523365096',
  appId: '1:78523365096:web:24f354d522e138fd3a3b5d',
  measurementId: 'G-6BKXSCXKT4',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
