<template>
  <b-row>
    <b-button
      v-b-modal.modal-uppuxador
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1"
      variant="success"
      @click="novoPuxador"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Nova Senha</span>
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="outline-success"
      @click="imprimirTodas('PROFISSIONAL')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">Profissional</span>
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="outline-success"
      @click="imprimirTodas('AMADOR')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">Amador</span>
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="outline-success"
      @click="imprimirTodas('INICIANTE')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">Iniciante</span>
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="outline-success"
      @click="imprimirTodas('FEMININO')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">Feminino</span>
    </b-button>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="outline-primary"
    >
      <downloadexcel
        :fetch="fetchData"
        :fields="json_fields"
        :before-generate="startDownload"
        :before-finish="finishDownload"
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        Download Excel
      </downloadexcel>
    </b-button>
    <!-- Modal Atualizar Vaqueiro -->
    <b-modal
      id="modal-uppuxador"
      :title="tituloModal"
      ok-only
      :ok-title="butaoModal"
      size="lg"
      @ok="executarMissao"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <h6>Categoria <span v-show="categoria != ''">- Valor: {{ valorKit }}</span></h6>
            <b-form-group>
              <v-select
                v-model="categoria"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionCategoria2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h6>Kit de Senha</h6>
            <b-form-group>
              <v-select
                v-model="kit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionKit"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Puxador"
              label-for="mc-last-name"
            >
              <b-form-input
                id="mc-last-name"
                v-model="row.nome"
                style="text-transform: uppercase"
                placeholder="Puxador"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cavalo Puxador"
              label-for="mc-city"
            >
              <b-form-input
                id="mc-city"
                v-model="row.cavaloPuxar"
                style="text-transform: uppercase"
                placeholder="Cavalo"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Estereiro"
              label-for="mc-country"
            >
              <b-form-input
                id="mc-country"
                v-model="row.estereiro"
                style="text-transform: uppercase"
                placeholder="Estereiro"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cavalo Estereiro"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.cavaloEsteira"
                  style="text-transform: uppercase"
                  placeholder="Cavalo"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cidade"
              label-for="mc-country"
            >
              <b-form-input

                id="mc-country"
                v-model="row.cidade"
                style="text-transform: uppercase"
                placeholder="Cidade"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Representação"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.representacao"
                  style="text-transform: uppercase"
                  placeholder="Representaçao"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <h6>Forma de Pagamento</h6>
            <b-form-group>
              <v-select
                v-model="row.formaPagamento"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionFp2"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <h6>Status</h6>
            <b-form-group>
              <v-select
                v-model="row.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionStatus2"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Desconto"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.desconto"
                  style="text-transform: uppercase"
                  placeholder="0"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Acréscimo"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.acrescimo"
                  style="text-transform: uppercase"
                  placeholder="0"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
          >
            <b-form-group
              label="Observação"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.obs"
                  style="text-transform: uppercase"
                  placeholder="obs"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-card
      no-body
      class="card-statistics col-12"
    >
      <b-card-header>
        <b-card-title>Totais de Kits Vendido: <b>{{ totalRows }}</b></b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="(item, index) in totais"
            :key="item.icon"
            xl="2"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  <div v-if="index < 4">
                    {{ item.title }}
                  </div>
                  <div v-else>
                    {{ moeda(item.title) }}
                  </div>

                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card class="col-12">
      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Por Página</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="lg"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="my-1"
        >
          <b-form-group
            label="Ordem"
            label-cols-lg="3"
            label-align-lg="right"
            label-size="lg"
            label-for="Ordenação"
            class="mb-0"
          >
            <b-input-group size="lg">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                size="lg"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- sem --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="lg"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Crescente
                </option>
                <option :value="true">
                  Decrescente
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="Filtro"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="md"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="lg">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Pesquisa"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Limpar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(status)="data">
            <b-badge :variant="variateStatus(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(acoes)="data">
            <b-row>
              <b-col
                v-b-modal.modal-uppuxador
              >
                <feather-icon
                  class="plus-icon"
                  size="16"
                  icon="PlusIcon"
                  @click="carregarSenha(data.item, 'Cadastrar Mais um Kit', 'Cadastrar', 1)"
                />
              </b-col>
              <b-col
                v-b-modal.modal-uppuxador
              >
                <feather-icon
                  class="color-icon"
                  size="16"
                  icon="EditIcon"
                  @click="carregarSenha(data.item, 'Atualizar Kit', 'Atualizar', 2)"
                />
              </b-col>
              <b-col>
                <feather-icon
                  class="color-icon"
                  size="16"
                  icon="PrinterIcon"
                  @click="imprimirSenha(data.item)"
                />
              </b-col>
              <b-col>
                <feather-icon
                  class="color-icon"
                  size="16"
                  icon="TrashIcon"
                  @click="cancelarKit(data.item)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-card>

  </b-row>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardHeader,
  BMedia, BAvatar, BMediaAside, BMediaBody, BBadge,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import downloadexcel from 'vue-json-excel'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardHeader,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
    vSelect,
    downloadexcel,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      esconder: false,
      tipo: 0,
      kit: '',
      categoria: '',
      idVaquejada: 41,
      host: 'http://localhost',
      optionCategoria2: ['PROFISSIONAL', 'TROPA DE ELITE', 'AMADOR', 'INICIANTE', 'FEMININO'],
      optionStatus2: ['PAGO', 'PENDENTE', 'DOADO', 'RESERVADO'],
      optionFp2: ['DINHEIRO', 'PIX', 'CARTAO', 'BOLETO', 'CHEQUE'],
      tituloModal: '',
      butaoModal: '',
      perPage: 10,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Id',
        },
        {
          key: 'senha', label: 'Senha 1', sortable: true,
        },
        { key: 'senha2', label: 'Senha 2' },
        { key: 'nome', label: 'Puxador', sortable: true },
        { key: 'categoria', label: 'Categoria', sortable: true },
        { key: 'antencipada', label: 'Antecipada', sortable: true },
        { key: 'valor', label: 'Valor' },
        { key: 'formaPagamento', label: 'F. Pagamento' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'acoes', label: 'Ações', class: 'd-flex' },
      ],
      items: [],
      mapa1: [],
      mapa2: [],
      mapa3: [],
      optionKit: [],
      row: {
        id: '',
        idVaquejada: '',
        nome: '',
        cpf: '',
        cidade: '',
        telefone: '',
        categoria: '',
        apelido: '',
        estereiro: '',
        cavaloPuxar: '',
        cavaloEsteira: '',
        senha: '',
        senha2: '',
        representacao: '',
        valor: '',
        formaPagamentoformaPagamento: '',
        status: '',
        desconto: '',
        acrescimo: '',
        dataCadastro: '2022-05-05 00:30:00',
        obs: '',
        imprimiu: '',
        dataConfirmacao: '',
      },
      totais: [],
      totalKit: 0,
      json_fields: {
        'Senha 1': 'senha1',
        'Senha 2': 'senha2',
        Categoria: 'categoria',
        Nome: 'puxador',
        Cidade: 'cidade',
        Representacao: 'representacao',
        Antencipada: 'antencipada',
        Valor: 'valor',
        Acrescimo: 'acrescimo',
        Desconto: 'desconto',
        'Forma Pagamento': 'forma_pagamento',
        Status: 'status',
        Obs: 'extra',
      },
    }
  },
  computed: {
    valorKit() {
      if (this.categoria === 'PROFISSIONAL') {
        return 'R$ 950,00'
      } if (this.categoria === 'AMADOR') {
        return 'R$ 950,00'
      } if (this.categoria === 'TROPA DE ELITE') {
        return 'R$ 500,00'
      } if (this.categoria === 'INICIANTE') {
        return 'R$ 400,00'
      } if (this.categoria === 'FEMININO') {
        return 'R$ 150,00'
      }
      return 0
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    categoria() {
      this.optionKit = []
      if (this.categoria === 'PROFISSIONAL') {
        this.disponiveisMapa('PROFISSIONAL')
      } else if (this.categoria === 'AMADOR') {
        this.disponiveisMapa('AMADOR')
      } else if (this.categoria === 'INICIANTE') {
        this.disponiveisMapa('INICIANTE')
      } else if (this.categoria === 'TROPA DE ELITE') {
        this.disponiveisMapa('TROPA DE ELITE')
      } else if (this.categoria === 'FEMININO') {
        this.disponiveisMapa('FEMININO')
      }
    },
  },
  created() {
    this.carregarKits()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    verDetalhes(props) {
      window.open(`${this.host}/senha/zePena/${props.id}`)
    },
    variateStatus(status) {
      if (status === 'PAGO') {
        return 'light-success'
      }
      return 'light-secondary'
    },
    disponiveisMapa(categoria) {
      const array = []
      if (categoria === 'PROFISSIONAL') {
        for (let i = 1; i < 100; i += 1) {
          const s2 = i + 100
          const s = String(i)
          const kit = `${s}-${s2}`
          const senhas = this.items.map(el => el.senha)
          if (!senhas.includes(s)) {
            array.push(kit)
          }
        }
        this.optionKit = array
      } else if (categoria === 'INICIANTE' || categoria === 'AMADOR') {
        for (let i = 400; i < 500; i += 1) {
          const s2 = i + 100
          const s = String(i)
          const kit = `${s}-${s2}`
          const senhas = this.items.map(el => el.senha)
          if (!senhas.includes(s)) {
            array.push(kit)
          }
        }
        for (let i = 600; i < 700; i += 1) {
          const s2 = i + 100
          const s = String(i)
          const kit = `${s}-${s2}`
          const senhas = this.items.map(el => el.senha)
          if (!senhas.includes(s)) {
            array.push(kit)
          }
        }
        for (let i = 800; i < 900; i += 1) {
          const s2 = i + 100
          const s = String(i)
          const kit = `${s}-${s2}`
          const senhas = this.items.map(el => el.senha)
          if (!senhas.includes(s)) {
            array.push(kit)
          }
        }
        this.optionKit = array
      } else if (categoria === 'FEMININO') {
        for (let i = 1000; i < 1100; i += 1) {
          const s2 = i + 100
          const s = String(i)
          const kit = `${s}-${s2}`
          const senhas = this.items.map(el => el.senha)
          if (!senhas.includes(s)) {
            array.push(kit)
          }
        }
        this.optionKit = array
      } else if (categoria === 'TROPA DE ELITE') {
        for (let i = 200; i < 300; i += 1) {
          const s2 = i + 100
          const s = String(i)
          const kit = `${s}-${s2}`
          const senhas = this.items.map(el => el.senha)
          if (!senhas.includes(s)) {
            array.push(kit)
          }
        }
        this.optionKit = array
      }
    },
    executarMissao() {
      if (this.tipo === 1) {
        this.cadastrarKit()
      } else if (this.tipo === 2) {
        this.atualizarKit()
      }
    },
    atualizarKit() {
      const ids = this.items.map(el => el.id)
      const url = `${this.host}/api/atualizar/?vaquejada=${this.idVaquejada}&id=${this.row.id}&categoria=${this.categoria}&senha=${this.kit}&puxador=${this.row.nome}&cavalo_puxador=${this.row.cavaloPuxar}&estereiro=${this.row.estereiro}&cavalo_estereiro=${this.row.cavaloEsteira}&cidade=${this.row.cidade}&representacao=${this.row.representacao}&forma_pagamento=${this.row.formaPagamento}&status=${this.row.status}&desconto=${this.row.desconto}&obs=${this.row.obs}&acrescimo=${this.row.acrescimo}`
      axios.get(url)
        .then(res => {
          if (res.data.resposta === 1) {
            const kit = this.kit.split('-')
            const s1 = kit[0]
            const s2 = kit[1]
            this.items[ids.indexOf(this.row.id)].valor = res.data.valor
            this.items[ids.indexOf(this.row.id)].desconto = this.row.desconto
            this.items[ids.indexOf(this.row.id)].acrescimo = this.row.acrescimo
            this.items[ids.indexOf(this.row.id)].categoria = this.categoria
            this.items[ids.indexOf(this.row.id)].senha = s1
            this.items[ids.indexOf(this.row.id)].senha2 = s2
            if (this.row.status === 'PAGO') {
              this.totais[4].title += parseFloat(res.data.valor)
              this.totais[5].title -= parseFloat(res.data.valor)
            } else if (this.row.status === 'PENDENTE') {
              this.totais[4].title -= parseFloat(res.data.valor)
              this.totais[5].title += parseFloat(res.data.valor)
            }
            this.limparDados()
            Swal.fire({
              icon: 'success',
              title: 'Kit atualizado com sucesso',
              text: '',
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            Swal.fire({
              title: 'Algo de errado aconteceu.',
              text: '',
              timer: 1500,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    cadastrarKit() {
      const url = `${this.host}/api/cadastrar/?vaquejada=${this.idVaquejada}&categoria=${this.categoria}&senha=${this.kit}&puxador=${this.row.nome}&cavalo_puxador=${this.row.cavaloPuxar}&estereiro=${this.row.estereiro}&cavalo_estereiro=${this.row.cavaloEsteira}&cidade=${this.row.cidade}&representacao=${this.row.representacao}&forma_pagamento=${this.row.formaPagamento}&status=${this.row.status}&desconto=${this.row.desconto}&obs=${this.row.obs}&acrescimo=${this.row.acrescimo}`
      const kit = this.kit.split('-')
      const senha = kit[0]
      axios.get(`${this.host}/api/verificarSenha/${this.idVaquejada}/${senha}`)
        .then(res2 => {
          if (res2.data === 0) {
            axios.get(url)
              .then(res => {
                if (res.data.resposta === 1) {
                  this.totalRows += 1
                  if (this.categoria === 'PROFISSIONAL') {
                    this.totais[0].title += 1
                  } else if (this.categoria === 'AMADOR') {
                    this.totais[1].title += 1
                  } else if (this.categoria === 'INICIANTE') {
                    this.totais[2].title += 1
                  } else if (this.categoria === 'FEMININO') {
                    this.totais[3].title += 1
                  }
                  if (this.row.status === 'PAGO') {
                    this.totais[4].title += parseFloat(res.data.valor)
                  } else if (this.row.status === 'PENDENTE') {
                    this.totais[5].title += parseFloat(res.data.valor)
                  }
                  this.kit = ''
                  const sd = {
                    id: res.data.id,
                    idVaquejada: this.idVaquejada,
                    nome: this.row.nome,
                    cpf: '',
                    cidade: this.row.cidade,
                    telefone: '',
                    categoria: this.categoria,
                    apelido: null,
                    estereiro: this.row.estereiro,
                    cavaloPuxar: this.row.cavaloPuxar,
                    cavaloEsteira: this.row.cavaloEsteira,
                    senha: kit[0],
                    senha2: kit[1],
                    representacao: this.row.representacao,
                    valor: res.data.valor,
                    formaPagamento: this.row.formaPagamento,
                    status: this.row.status,
                    desconto: this.row.desconto,
                    acrescimo: this.row.acrescimo,
                    dataCadastro: '2022-05-05 00:30:00',
                    obs: this.row.obs,
                    imprimiu: '',
                    antencipada: 'NAO',
                    dataConfirmacao: null,
                  }
                  this.items.unshift(sd)
                  this.limparDados()
                  Swal.fire({
                    icon: 'success',
                    title: 'Cadastro realizado com sucesso',
                    text: '',
                    timer: 1500,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  window.open(`${this.host}/imprimir_senha1/${res.data.id}`)
                } else {
                  Swal.fire({
                    title: 'Algo de errado aconteceu.',
                    text: '',
                    timer: 1500,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              })
          } else {
            Swal.fire({
              title: 'Kit Reservado',
              text: 'Selecione outro kit',
              timer: 2000,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    novoPuxador() {
      this.tituloModal = 'Cadastrar Nova Senha'
      this.butaoModal = 'Cadastrar'
      this.categoria = ''
      this.kit = ''
      this.tipo = 1
    },
    carregarSenha(props, plus, butao, tipo) {
      this.categoria = props.categoria
      this.tituloModal = plus
      this.butaoModal = butao
      this.tipo = tipo
      if (tipo === 2) {
        this.kit = `${props.senha}-${props.senha2}`
      }
      axios.get(`${this.host}/api/senha/${props.id}`)
        .then(() => {
          this.row = props
        })
    },
    carregarKits() {
      let totalProfissional = 0
      let totalAmador = 0
      let totalIniciante = 0
      let totalFeminino = 0
      let totalPago = 0
      let totalPendente = 0
      axios.get(`${this.host}/api/kits/${this.idVaquejada}`)
        .then(res => {
          this.items = res.data.reverse()
          this.totalRows = this.items.length
          for (let i = 0; i < this.totalRows; i += 1) {
            if (this.items[i].categoria === 'PROFISSIONAL') {
              totalProfissional += 1
            } else if (this.items[i].categoria === 'AMADOR') {
              totalAmador += 1
            } else if (this.items[i].categoria === 'INICIANTE') {
              totalIniciante += 1
            } else if (this.items[i].categoria === 'FEMININO') {
              totalFeminino += 1
            }
            if (this.items[i].status === 'PAGO') {
              totalPago += parseFloat(this.items[i].valor)
            } else if (this.items[i].status === 'PENDENTE') {
              totalPendente += parseFloat(this.items[i].valor)
            }
          }
          const Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalProfissional,
              subtitle: 'PROFISSIONAL',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalAmador,
              subtitle: 'AMADOR',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalIniciante,
              subtitle: 'Iniciante',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: totalFeminino,
              subtitle: 'FEMININO',
              customClass: '',
            },
            {
              icon: 'DollarSignIcon',
              color: 'light-success',
              title: totalPago,
              subtitle: 'PAGO',
              customClass: '',
            },
            {
              icon: 'DollarSignIcon',
              color: 'light-danger',
              title: totalPendente,
              subtitle: 'PENDENTE',
              customClass: '',
            },
          ]
          this.totais = Totais
        })
    },
    imprimirSenha(props) {
      window.open(`${this.host}/imprimir_senha1/${props.id}`)
    },
    imprimirTodas(categoria) {
      window.open(`${this.host}/imprimir/todas/${this.idVaquejada}/${categoria}`)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    moeda(valor) {
      return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    cancelarKit(props) {
      const linhas = this.items
      Swal.fire({
        title: `Cancelar o kit ${props.senha}-${props.senha2}?`,
        text: 'Essa operacão não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Cancelar Kit',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`${this.host}/api/cancelarKit/${props.id}`)
            .then(res => {
              if (res.data === 1) {
                const ids = linhas.map(el => el.id)
                this.items.splice(ids.indexOf(props.id), 1)
                this.totalRows -= 1
                if (props.categoria === 'PROFISSIONAL') {
                  this.totais[0].title -= 1
                } else if (props.categoria === 'AMADOR') {
                  this.totais[1].title -= 1
                } else if (props.categoria === 'INICIANTE') {
                  this.totais[2].title -= 1
                } else if (props.categoria === 'FEMININO') {
                  this.totais[3].title -= 1
                }
                if (props.status === 'PAGO') {
                  this.totais[4].title -= parseFloat(props.valor)
                } else if (props.status === 'PENDENTE') {
                  this.totais[5].title -= parseFloat(props.valor)
                }
              }
            })
          Swal.fire({
            icon: 'success',
            title: 'Kit Cancelado',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          Swal.fire({
            title: 'Operacão cancelada',
            text: '',
            timer: 1500,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    limparDados() {
      this.kit = ''
      this.categoria = ''
      this.row = {
        id: '',
        idVaquejada: '',
        nome: '',
        cpf: '',
        cidade: '',
        telefone: '',
        categoria: '',
        apelido: '',
        estereiro: '',
        cavaloPuxar: '',
        cavaloEsteira: '',
        senha: '',
        senha2: '',
        representacao: '',
        valor: '',
        formaPagamentoformaPagamento: '',
        status: '',
        desconto: '',
        acrescimo: '',
        dataCadastro: '2022-05-05 00:30:00',
        obs: '',
        imprimiu: '',
        dataConfirmacao: '',
      }
    },
    async fetchData() {
      const dd = []
      const response = await axios.get(`${this.host}/api/kits/${this.idVaquejada}`)
      response.data.map(el => dd.push({
        senha1: el.senha,
        senha2: el.senha2,
        categoria: el.categoria,
        puxador: el.nome,
        cidade: el.cidade,
        representacao: el.representacao,
        antencipada: el.antencipada,
        valor: el.valor,
        acrescimo: el.acrescimo,
        desconto: el.desconto,
        forma_pagamento: el.formaPagamento,
        status: el.status,
        extra: el.obs,
      }))
      return dd
    },
    startDownload() {
      Swal.fire({
        icon: 'success',
        title: 'Gerando Relatório',
        text: '',
        timer: 1000,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    finishDownload() {
      Swal.fire({
        icon: 'success',
        title: 'Relatório Gerado com Sucesso',
        text: '',
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
